export enum ErrorMessages {
  Required =  'Обов\'язкове поле',
  HHmm = 'Має бути у форматі гг:хх',
  HHmmddMMyyyy = 'Має бути у форматі гг:хх, дд.мм.рррр',
}

export const configKey = 'config';
export const reportsKey = 'reports_v4';
export const targetMapKey = 'targets';

export const mgrsCoordinatesRegExp = /^\d{2}[A-Z] [A-Z]{2} \d{5} \d{5}$/;
export const mgrsCoordinatesStartRegExp = /^\d{2}[A-Z] [A-Z]{2}$/;

export enum ViewType {
  Reports = 'Reports',
  Config = 'Config',
}

export enum Detonators {
  StandBy = 'Штатний',
  MYV1 = 'МУВ1',
  AMA = 'АМ-А',
  MD2 = 'МД-2',
  MD5M = 'МД-5М',
  KD8A = 'КД-8А',
  EDP = 'ЕДП-Р',
  EDP2 = 'ЕДП',
  NoContact = 'Неконтактний',
  FC1 = 'FC-1',
  FAC1 = 'FAC-1'
}

export const detonatorOptions = Object.values(Detonators);

export enum Initiators {
  StandBy = 'Штатний',
  InerticShockWorker = 'Інерційний накольник',
  ShockWorker = 'Накольник',
  MUV1 = 'МУВ-1',
  MVCH = 'МВЧ',
  MD2 = 'МД-2',
  Djonik = 'Джонік'
}

export const initiatorOptions = Object.values(Initiators);

// export const ammoOptions = ['ТМ-62', 'МОА-400', 'МОА-900', 'АЗБ-5М', 'ФАБ-400', 'ФАБ-900', 'ПФМ'];
export enum Ammos {
  TM62 = 'ТМ 62М',
  MOA400 = 'МОА-400',
  MOA900 = 'МОА-900',
  //AZB5M = 'АЗБ-5М',
  ZAB25C = 'ЗАБ-2.5С',
  FAB85 = 'ФАБ-8.5-А',
  OGB = 'ОГ-Б1',
  PG7 = 'ПГ7-ВЛ',
  RKG1600AY = 'РКГ-1600 АУ',
  BF10T = 'BF-10T',
  BAAT10 = 'BAAT-10',
  PFM = 'ПФМ',
  PTM3 = 'ПТМ-3',
}
export const ammoOptions = Object.values(Ammos);

type AmmoConfig = {
  [x: string]: {
    initiator: Initiators,
    detonator: Detonators,
  }
}
export const ammoConfig: AmmoConfig = {
  [Ammos.TM62]: {
    initiator: Initiators.InerticShockWorker,
    detonator: Detonators.MD2,
  },
  [Ammos.MOA400]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.MOA900]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.FAB85]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  //[Ammos.AZB5M]: {
  //  initiator: Initiators.ShockWorker,
  //  detonator: Detonators.AMA,
  //},
  [Ammos.PFM]: {
    initiator: Initiators.StandBy,
    detonator: Detonators.StandBy,
  },
  [Ammos.ZAB25C]: {
    initiator: Initiators.MUV1,
    detonator: Detonators.MD5M,
  },
  [Ammos.OGB]: {
    initiator: Initiators.StandBy,
    detonator: Detonators.StandBy,
  },
  [Ammos.PTM3]: {
    initiator: Initiators.Djonik,
    detonator: Detonators.EDP2,
  },
  [Ammos.PG7]: {
    initiator: Initiators.InerticShockWorker,
    detonator: Detonators.MD2,
  },
  [Ammos.RKG1600AY]: {
    initiator: Initiators.InerticShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.BF10T]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.FC1,
  },
  [Ammos.BAAT10]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.FAC1,
  },
}

export enum Objectives {
  HumanitarianCargo = 'Доставка провізії',
  HumanitarianCargo1 = 'Гуманітарний вантаж',
  Hit = 'Ураження',
  Mining = 'Мінування',
  MetalHed = 'Скидання металевих їжаків',
  Barrage = 'Загородження з МПП',
  Barrage2 = 'Загородження з МЗП',
}

export const objectiveOptions = Object.values(Objectives);

export enum Results {
  Executed = 'Виконано',
  Hit = 'Уражено',
  Fired = 'Обстріляно',
  Destroyed = 'Знищено',
  NoDetonation = 'Без детонації',
  Missed = 'Невлучання',
  NotDone = 'Не виконано',
  EmergencyLanding = 'Аварійна посадка',
  Lost = 'Борт втрачено',
}
export const resultOptions = Object.values(Results);

export const defaultInitialTargetValue = '36T XT';
export const initialAmmoValue = {
  ammoType: '',
  ammoQuantity: '',
  detonator: '',
  detonatorQuantity: '',
  initiator: '',
  initiatorQuantity: '',
}
